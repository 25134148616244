export default function () {
  const { locale, localeProperties } = useI18n();
  const isRTL = computed(() => (localeProperties.value.dir === "rtl"))
  const direction = computed(() =>localeProperties.value.dir)
  return {
    locale,
    direction,
    isRTL,
  };
}
